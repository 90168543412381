<template>
  <p class="mb-0 text-caption text-muted">{{ version }}</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as pkg from '../../package.json'
export default defineComponent({
  name: 'VersionComponent',
  data() {
    return {
      version: `${pkg.version} ${process.env.VUE_APP_ENV_NAME != 'PROD' ? process.env.VUE_APP_ENV_NAME : ''}`
    }
  },

})
</script>
