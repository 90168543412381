import { closeOffCanvas, EOffcanvasViews, navigateCanvas } from '@/components/Offcanvas/OffcanvasViews/OffcanvasViewsController';
import ListControllerMixin from '@/mixins/ListControllerMixin';
import { useMainStore } from '@/store';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
export default defineComponent({
  mixins: [ListControllerMixin],
  props: {
    repaymentId: {
      type: String,
      default: ''
    },
    appointment: {
      type: Object,
      default: () => { }
    },
    hasRemove: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return {
      userSession: session,
    }
  },
  mounted() {
    this.$eventBus.$on('onCreditCardAdded', this.getData)
  },
  data() {
    return {
      selectedCard: '',
      loading_repayment: false
    };
  },
  methods: {
    fetchPromise() {
      return this.$paymentService.getCreditCards()
    },
    handleSelectCard(item: any) {
      console.log('handleSelectCard', this.repaymentId)
      this.$paymentService.setDefaultPaymentMethod({
        type: 'card',
        info: item
      })
      if (!this.repaymentId) {
        closeOffCanvas()
        this.$emit('onCardSelected')
      }
    },
    handleAdd() {
      navigateCanvas(EOffcanvasViews.addCreditCard)
    },
    handleRemove(card: any) {
      this.$eventBus.$emit("showConfirmationAlert", {
        message: `Tem certeza que deseja remover o cartão *** ${card.last_digits}`,
        confirmCallback: async () => {
          try {
            this.items = this.items.filter((elem: any) => elem.card_id != card.card_id)
            this.$eventBus.$emit('showToast', { title: 'Cartão removido', msg: 'Seu cartão foi removido com sucesso' })
            if (card.card_id == this.userSession?.default_payment_method?.info?.card_id) {
              if (this.items.length) {
                this.handleSelectCard(this.items[this.items.length - 1])
              } else {
                this.handleSelectCard(null)
              }
            }
            await this.$paymentService.removeCard(card.card_id)
          } catch (e) {
            console.log(e);
          } finally {
          }
        },
      });
    },
    async handleRepayment() {
      try {
        this.loading_repayment = true
        await this.$appointmentService.paymentRetry(this.repaymentId)
        closeOffCanvas()
        this.$eventBus.$emit('refreshAppointment')
      } catch (e: any) {
        this.$eventBus.$emit('showErrorToast', { msg: e?.message || "Não foi possível refaser o pagamento da consulta" })
      } finally {
        this.loading_repayment = false
      }
    }
  }
})
