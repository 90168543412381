<template>
  <div class="row justify-content-center">
    <div class="col-12 mt-4">
      <p class="text-paragraph">
        Caso você precise de ajuda com o aplicativo, estaremos à disposição para te ajudar no email abaixo
      </p>
      <a :href="email" class="btn btn-outline-primary w-100 mt-6">
        Enviar um email
      </a>
      <a :href="whatsappLink" class="btn btn-success w-100 mt-4" target="_blank">
        <span class="fa-brands fa-medium-size me-2 fa-whatsapp"></span>
        Enviar uma mensagem
      </a>
    </div>
  </div>
</template>
<script>
import HelpController from "./HelpController";
export default HelpController;
</script>
<style scoped></style>
