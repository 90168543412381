import { toggleModal } from "@/components/ModalComponent.vue";
import { useMainStore } from "@/store";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required, sameAs } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    name: "PasswordUpdate",
    setup() {
        const store = useMainStore()
        const { session } = storeToRefs(store)
        return {
            v$: useVuelidate(),
            userSession: session
        }
    },
    data() {
        return {
            errorMessage: "",
            submitting: false,
            form: {
                old_password: "",
                password: "",
                passwordConfirmation: "",
            } as any,
        };
    },
    validations() {
        return {
            form: {
                old_password: {
                    required: helpers.withMessage("Informe uma senha com no mínimo 8 caractéres", required),
                    minLength: helpers.withMessage('Informe uma senha com no mínimo 8 caractéres', minLength(8)),
                },
                password: {
                    required: helpers.withMessage("Informe uma senha com no mínimo 8 caractéres", required),
                    minLength: helpers.withMessage('Informe uma senha com no mínimo 8 caractéres', minLength(8)),
                },
                passwordConfirmation: {
                    required: helpers.withMessage('As senhas não correspondem', required),
                    sameAsPassword: helpers.withMessage('As senhas não correspondem', sameAs(this.form.password)),
                },
            },
        }
    },
    methods: {
        async submit() {
            try {
                if (this.userSession) {
                    this.submitting = true;
                    this.errorMessage = "";
                    const form = { ...this.form };
                    delete form.passwordConfirmation;
                    const res = await this.$userService.update(this.userSession?.id, form);
                    this.$eventBus.$emit("showToast", {
                        title: "Senha alterada",
                        msg: `Sua senha foi alterada com sucesso`,
                    });
                    this.v$.$reset()
                    Object.keys(this.form).map((key: string) => {
                        this.form[key] = ''
                    })
                    toggleModal('password_update')
                }
            } catch (error: any) {
                this.errorMessage = error.message;
            } finally {
                this.submitting = false;
            }
        },
    },
});
