import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OffcanvasComponent = _resolveComponent("OffcanvasComponent")!

  return (_openBlock(), _createBlock(_component_OffcanvasComponent, {
    id: `offcanvas_views`,
    title: _ctx.title,
    onOffcanvasClosed: _ctx.offcanvasClosed
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.View), _normalizeProps(_guardReactiveProps(_ctx.viewProps)), null, 16))
      ])
    ]),
    _: 1
  }, 8, ["title", "onOffcanvasClosed"]))
}