import Pusher from 'pusher-js'

class WebSocketService {
	private pusher!: Pusher
	private PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY
	private PUSHER_OPTIONS = { cluster: 'us2' }
	static instance: WebSocketService;

	constructor() {
		if (!WebSocketService.instance) {
			console.log("INICIALIZANDO PUSHER");

			this.pusher = new Pusher(this.PUSHER_KEY, this.PUSHER_OPTIONS);

			setTimeout(() => {
				const state = this.pusher.connection.state;
				console.log("STATUS DO PUSHER:", state);
				if (state !== "connected") {
					console.log("DEU RUIM NO PUSHER");
				}
			}, 15000);

			WebSocketService.instance = this;
		}

		return WebSocketService.instance;
	}

	connect = () => {
		console.log("CONECTANDO PUSHER");
		this.pusher.connect();
	};

	subscribe = (value: string) => {
		console.log("INSCREVENDO NO CANAL:", value);
		return this.pusher?.subscribe(value);
	}

	unsubscribe = (channelName: string, eventName: string) => {
		console.log("DESINSCREVENDO DO CANAL:", channelName, "EVENTO:", eventName);
		this.pusher.unsubscribe(channelName)
		this.pusher.unbind(eventName)
	}

	disconnect = () => {
		console.log("DISCONECTANDO PUSHER");
		this.pusher.disconnect();
	};
}

const webSocketService = new WebSocketService();
export default webSocketService;


// import Pusher from 'pusher-js'

// export default class WebSocketService {
// 	constructor() { }
// 	getInstance() {
// 		return new Pusher(process.env.VUE_APP_PUSHER_KEY, {
// 			cluster: 'us2'
// 		});
// 	}
// }
