import { closeOffCanvas } from '@/components/Offcanvas/OffcanvasViews/OffcanvasViewsController';
import ListControllerMixin from '@/mixins/ListControllerMixin';
import { defineComponent } from 'vue';
export enum NOTIFICATION_TYPES {
  CHAT_MESSAGE = "appointment_chat_message",
  APPOINTMENT = "appointment"
}
export default defineComponent({
  mixins: [ListControllerMixin],
  mounted() {
  },
  data() {
    return {

    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.listNotifications({})
    },
    prepareResponse(data: any) {
      return data
    },
    handleDetail(item: any) {
      switch (item.target_type) {
        case NOTIFICATION_TYPES.CHAT_MESSAGE:
        //TODO: fazer isso quando o chat tiver pronto this.$router.push({ path: `${this.$paths.orders.detail}/${id}` });
        case NOTIFICATION_TYPES.APPOINTMENT:
          this.$router.push({ path: `${this.$paths.appointments.detail}/${item.target_id}` });
          closeOffCanvas()
      }
    }
  }
})
