<template>
  <!-- <LoadingContainer v-if="loadingItems" /> -->


  <div class="col-12">
    <div class="card rounded-5 banner-card bg-secondary10">
      <div class="card-body align-items-center justify-content-center d-flex">
        <PhIcons name="case" :size="60" color="#FBA486" />
      </div>
    </div>
    <p class="text-paragraph">
      Caso a empresa que você trabalha seja uma parceira do Psiapp, você pode ter acesso a valores de sessões
      diferenciados para você. Ative o código abaixo.
    </p>
    <template v-if="userSession?.partnership">
      <div class="list-group list-group-flush my-3 ">
        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0 text-primary">
                Código
              </h5>
            </div>
            <div class="col-auto">
              <h5 class="text-primary fw-bold">
                {{ userSession?.partnership?.info?.code }}
              </h5>
            </div>
          </div>
        </div>
        <div class="list-group-item border-0">
          <div class="row align-items-center">
            <div class="col">
              <h5 class="mb-0 text-primary">
                Empresa
              </h5>
            </div>
            <div class="col-auto">
              <h5 class="text-primary fw-bold">
                {{ userSession?.partnership?.info?.company_name }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <button @click="handleRemove" class="btn btn-outline-danger w-100 mt-3 mb-4">
        <span v-if="submitting" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
        Remover benefício
      </button>
      <router-link class="btn btn-secondary text-primary w-100" :to="`${$paths.searchPsi}`">
        Ver psis disponíveis
      </router-link>
    </template>
    <template v-else>
      <input class="form-control border-0 text-primary ps-0" v-model.trim="code" type="text" :maxlength="30"
        placeholder="Digite o código aqui" />
      <FormButton label="Ativar código" :loading="submitting" :disabled="!code" class="my-4" @onClick="handleSubmit" />
    </template>

    <template v-if="!userSession?.partnership">
      <hr>
      <h3 class="text-header mt-4 mb-3">Ainda não é parceiro do Psiapp?</h3>
      <p class="text-paragraph">
        Caso a empresa que você trabalha não seja uma parceira do Psiapp, mas gostaria que fosse, entre em contato
        no
        botão abaixo para falar com um de nossos especialistas.
      </p>
      <button @click="handleGetInTouch" class="btn btn-outline-primary w-100 mt-3">
        Quero saber mais
      </button>
    </template>
  </div>

</template>
<script>
import PartnershipDetailController from "./PartnershipDetailController";
export default PartnershipDetailController;
</script>
<style scoped>
.banner-card {
  height: 250px;
}
</style>
