<template>
  <div>
    <ModalComponent title="Tem certeza que deseja excluir sua conta?'" id="account_delete" modalClass="modal-lg" backdrop="false">
      <template v-slot:body>
        <!-- <p class="text-header">Tem certeza que deseja deletar sua conta?</p> -->
        <p class="text-paragraph">
          Ao excluir sua conta, você irá perder acesso ao aplicativo e todas as suas configurações. Deseja realmente
          excluir sua conta?
        </p>
      </template>
      <template v-slot:footer>
        <button class="btn btn-danger" @click="handleAccountDelete">
          <span v-if="submitting" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
          <span> Sim, deletar conta </span>
        </button>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import AccountDeleteController from "./AccountDeleteController.ts";
export default AccountDeleteController;
</script>
