<template>
  <div>
        <PasswordInput
          label="Senha"
          :model="v$.form.old_password"
          placeholder="Senha atual"
        />
        <PasswordInput
          label="Nova senha"
          :model="v$.form.password"
          placeholder="Mínimo de 8 caracteres"
        />
        <PasswordInput
          label="Confirme a senha"
          :model="v$.form.passwordConfirmation"
          placeholder="Confirme sua senha"
          errorMsg="As senhas nao coincidem"
        />
        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>
        <FormButton
          class="w-100"
          label="Concluir"
          :disabled="v$.$invalid"
          @onClick="submit"
          :form="v$.form"
          :marginVertical="false"
          :loading="submitting"
        />
  </div>
</template>

<script>
import PasswordUpdateController from "./PasswordUpdateController";
export default PasswordUpdateController;
</script>
