<template>
  <div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <TextInput :readOnly="true" label="Nome" :value="userSession?.name" />
        </div>
        <div class="col-12">
          {{ userSession?.document_number?.clear() }}
          <TextInput :readOnly="true" label="Número do documento" :value="documentNumber" />
        </div>
        <div class="col-12">
          <TextInput :readOnly="true" label="Apelido" :value="userSession?.nickname" />
        </div>
        <div class="col-12">
          <TextInput :readOnly="true" label="Telefone" :value="userSession?.formatted_phone" />
        </div>
        <div class="col-12">
          <TextInput :readOnly="true" label="Email" :value="userSession?.email" />
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-lg-4">
        <button class="btn  btn-sm btn-outline-secondary w-100" @click="navigateCanvas(EOffcanvasViews.accountUpdate)">
          Editar
        </button>
      </div>
      <div class="col-12 col-lg-4">
        <button class="btn  btn-sm btn-outline-secondary w-100" @click="navigateCanvas(EOffcanvasViews.passwordUpdate)">
          Alterar senha
        </button>
      </div>
      <div class="col-12 col-lg-4">
        <button class="btn  btn-sm btn-danger w-100" @click="handleToggleModal('account_delete')">
          Remover conta
        </button>
      </div>
    </div>
    <AccountDelete />
  </div>
</template>

<script>
import MyAccountController from "./MyAccountController";
export default MyAccountController;
</script>
