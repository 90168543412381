import moment from 'moment';

export function isNameValid(value: string) {
    var ok = true
    var pattern = new RegExp('^(?=.*[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)$');
    try {
        const fullName = value.trim().split(' ')
        if (fullName.length < 2) {
            return false
        }
        if (fullName[0].length < 2) return false
        fullName.map(r => {
            if (!pattern.test(r) || r.length < 2) {
                console.log(r, pattern.test(r))
                ok = false
            }
        })
        return ok
    } catch (e) {
        return false
    }
}
export function isDateFuture(value: string) {
    if (!value) return false
    try {
        const date = moment(value)
        if (!date.isValid()) return false
        return date.isSameOrAfter(moment(), 'day')
    } catch (e) {
        return false
    }
}

export function isCPFValid(value: string | null) {
    if (!value) return true
    const invalidCpfs = [
        "00000000000",
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999",
    ]
    var Soma;
    var Resto;
    var i;

    value = value.replace(/[^\w\s]/gi, '').replace(' ', '')

    Soma = 0;
    if (invalidCpfs.includes(value) || !value) return false;

    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(value.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(value.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(value.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(value.substring(10, 11))) return false;
    return true;

}