import { useMainStore } from "@/store";
import NetworkService, { Endpoint } from "./NetworkService";

export interface IPaymentMethod {
	type: 'boleto' | 'card',
	info?: any
}

export default class PaymentService {
	network
	constructor() {
		this.network = new NetworkService()
	}
	static PAYMENT_METHODS = {
		boleto: 'boleto',
		card: 'card',
		partnership: 'partnership',
	}

	async getCreditCards() {
		const res = await this.network.get(Endpoint.cards)
		const cards = res.data.map((c: any) => {
			return {
				card_id: c.id,
				last_digits: c.card.last4,
			}
		})
		const store = useMainStore()
		let currentPaymentMethod = store.session?.default_payment_method
		let paymentMethod: IPaymentMethod | null = null
		if (!currentPaymentMethod) {
			if (cards.length) {
				paymentMethod = {
					type: 'card',
					info: cards[0]
				}
			}
			this.setDefaultPaymentMethod(paymentMethod)
		}
		return { status: true, data: cards }
	}
	createCreditCard(card_hash: string) {
		return this.network.post(Endpoint.cards, { card_hash })
	}
	setDefaultPaymentMethod(paymentMethod: IPaymentMethod | null) {
		const store = useMainStore()
		store.updateSession({ default_payment_method: paymentMethod })
	}
	removeCard(card_id: string) {
		return this.network.delete(Endpoint.cards, { card_id })
	}
	getCardErrorMessage(stripeErrorCode: string) {
		let msg = ''
		switch (stripeErrorCode) {
			case 'invalid_expiry_month':
				msg = 'Mês de vencimento do cartão inválido'
				break;
			case 'invalid_expiry_year':
				msg = 'Ano de vencimento do cartão inválido'
				break;
			case 'invalid_cvc':
				msg = 'Código de segurança do cartão inválido'
				break;
			case 'incorrect_number':
				msg = 'Número do cartão inválido'
				break;
			default:
				msg = 'Verifique as informações do seu cartão e tente novamente'
		}
		return msg
	}

	acceptedCards() {
		return [
			{
				id: 1,
				image: require('@/assets/img/card_brands/visa.png')
			},
			{
				id: 2,
				image: require('@/assets/img/card_brands/master.png')
			},
		]
	}

	validateCoupon(code: string, psychologist_id: string | number) {
		return this.network.post(Endpoint.validateCoupon, { code, psychologist_id })
	}
}
