<template>

  <div class="border border-primary mt-3 p-4 rounded-5 text-primary cursor-pointer" @click="handleSelectCard">
    <div class="row">
      <div class="col-auto">
        <h5 class=" mb-0">
          <img src="@/assets/img/credit-card.svg" class="" alt="">
          <span class="ms-3 fw-bold">Cartão de crédito</span>
          <template v-if="paymentMethod?.type == 'card'">
            <span id="card-number-info" class="fw-bold"> **** </span> <span>{{ paymentMethod.info?.last_digits }}</span>
          </template>
        </h5>
      </div>
      <div class="col text-end text-subtitle">
        <span class="fas fa-chevron-right"></span>
      </div>
    </div>
  </div>
  <div class="border border-primary mt-3 p-4 rounded-5 text-primary cursor-pointer"
    :class="{ 'opacity-25': !boletoAvailable }" @click="handleSelectBoleto">
    <div class="row">
      <div class="col-auto">
        <h5 class="fw-bold mb-0">
          <img src="@/assets/img/boleto.svg" class="" alt="">
          <span class="ms-3">Boleto</span>
        </h5>
      </div>
      <div class="col text-end text-subtitle">
        <span class="fas fa-chevron-right"></span>
      </div>
    </div>
  </div>
  <div class="card text-primary bg-warning10 mx-2 mt-3" id="boleto-unavailable-card" v-if="!boletoAvailable">
    <div class="card-body">
      <h4 class="fw-bold">
        Boleto indisponível
      </h4>
      Indisponível no momento. O boleto só pode ser usado para sessões com pelo menos 7 dias de antecedência.
    </div>
  </div>
</template>
<script lang="ts">
import { closeOffCanvas, EOffcanvasViews, navigateCanvas } from '@/components/Offcanvas/OffcanvasViews/OffcanvasViewsController';
import { IPaymentMethod } from '@/services/PaymentService';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  setup() {
  },
  props: {
    paymentMethod: {
      type: Object as PropType<IPaymentMethod | null>,
      required: false,
      default: null
    },
    boletoAvailable: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  methods: {
    handleSelectCard() {
      navigateCanvas(EOffcanvasViews.creditCards)
    },
    handleSelectBoleto() {
      if (!this.boletoAvailable) return
      this.$paymentService.setDefaultPaymentMethod({
        type: 'boleto',
        info: null
      })
      closeOffCanvas()
    },
  }
})

</script>
<style scoped></style>
