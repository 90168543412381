<template>
  <div class="">
    <div class="form-check form-switch mt-1 mb-2">
      <input class="form-check-input" v-model="foreignDocument" type="checkbox" id="countrySwitch">
      <label class="form-label text-muted small mb-0" for="countrySwitch">Resido fora do
        Brasil</label>
    </div>
    <TextInput :required="true" label="" :maxLength="30" :model="v$.documentNumber" type="text"
      :inputClass="'border-0 text-primary ps-0'"
      :placeholder="foreignDocument ? 'Informe o número do documento' : 'Informe o CPF'" :errorMsg="errorMessage"
      :mask="foreignDocument ? null : '###.###.###-##'" />
    <span v-if="v$.documentNumber.$invalid" id="error-msg-container"></span>
  </div>
</template>

<script lang="ts">
import { useMainStore } from '@/store';
import { EventBus } from '@/utils/EventBus';
import { isCPFValid } from '@/utils/FormValidators';
import useVuelidate, { ValidationRule } from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';

export type TDocumentInputResponse = {
  value: string,
  valid: boolean,
}


export default defineComponent({
  name: "DocumentInput",
  props: {
    validatePaymentMethod: { type: Boolean, default: true }
  },
  emits: ['valueChanged'],
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    return {
      v$: useVuelidate(),
      userSession: session
    }
  },

  created() {
    this.setupDocument()
    EventBus.$on('accountUpdated', this.setupDocument)
  },
  data() {
    return {
      foreignDocument: false,
      documentNumber: ''
    }
  },
  computed: {
    errorMessage(): string {
      return this.v$.documentNumber.$errors.find((e: any) => e)?.$message || 'Informe um documento válido'
    }
  },

  watch: {
    'v$': {
      handler() {
        this.sendValue()
      },
      deep: true
    }
  },
  methods: {
    sendValue() {
      const response: TDocumentInputResponse = {
        value: this.documentNumber,
        valid: !this.v$.documentNumber.$invalid,
      }
      console.log(JSON.stringify(this.v$))
      console.log('aaaaa sendValue', response, this.foreignDocument, this.documentNumber)
      this.$emit('valueChanged', response)
    },
    setupDocument() {
      this.documentNumber = this.userSession?.document_number || ''
      if (this.documentNumber === '') {
        this.foreignDocument = false
      } else {
        this.foreignDocument = !isCPFValid(this.documentNumber)
      }
    }
  },
  validations() {
    return {
      documentNumber: this.foreignDocument
        ? {
          required: helpers.withMessage("Informe um documento para o recibo", required),
          minLength: helpers.withMessage("Informe os dados para recibo", minLength(3)),
          cpfRequiredWhenBoleto: (this.userSession?.default_payment_method?.type === 'boleto' && this.validatePaymentMethod) ? helpers.withMessage("Para pagamento com boleto, é necessário informar um cpf válido mesmo não morando no Brasil.", (value: any) => isCPFValid(value)) : {}
        }
        : {
          required: helpers.withMessage("Informe um CPF para o recibo", required),
          isValidCPF: helpers.withMessage("Informe um CPF válido", (value: any) => isCPFValid(value))
        }
    };
  }
});
</script>
