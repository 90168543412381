<template>

  <StripeElements v-if="stripeLoaded" v-slot="{ elements, instance }" ref="elms" :stripe-key="stripeKey"
    :instance-options="instanceOptions" :elements-options="elementsOptions">
    <div class="form-group">
      <label for="" class="form-label text-muted">
        Número do cartão
      </label>
      <StripeElement ref="cardNumber" type="cardNumber" class="form-control" :elements="elements"
        :options="cardNumberOptions" />
    </div>

    <div class="form-group">
      <label for="" class="form-label text-muted"> Validade </label>
      <StripeElement type="cardExpiry" ref="cardExpiry" class="form-control" :elements="elements"
        :options="cardExpiryOptions" />
    </div>

    <div class="form-group">
      <label for="" class="form-label text-muted"> Cód </label>
      <StripeElement type="cardCvc" ref="cardCvc" class="form-control" :elements="elements" :options="cardCvcOptions" />
    </div>
  </StripeElements>
  <button type="button" class="btn btn-secondary text-primary w-100 mt-4" @click="handleAddCreditCard">
    <span v-if="submitting" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
    <span v-else> Concluir </span>
  </button>

</template>

<script>
import AddCardController from "./AddCardController.ts";
export default AddCardController;
</script>
