<template>
  <div id="orders">
    <LoadingContainer v-if="loadingItems" />
    <div v-else>
      <EmptyState v-if="!items.length" :totalItemsRegistered="items.length">
        <template v-slot:noItemsRegistered>
          <span class="fa-3x far fa-calendar text-black-50 mb-2"></span>
          <p>Nenhuma notificação</p>
        </template>
      </EmptyState>
      <template v-else>
        <div class="list-group list-group-flush my-n3 px-2">
          <div class="list-group-item text-primary cursor-pointer" @click="handleDetail(item)"
            :class="{ 'bg-primary05': !item.read }" v-for="(item, index) in items">
            <div class="row">
              <div class="col ms-n2">
                <h5 class="mb-1 fw-bold">
                  <span v-if="!item.read" class="fas fa-circle me-2"></span>
                  {{ item.title }}
                </h5>
                <p class="small mb-0">
                  {{ item.description }}
                </p>
                <small class="text-gray-700">
                  {{ item.created_at.toFormattedDate(1, true) }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- <div v-else class="table-responsive">
            <table ref="myTable" class="table last-end table-sm table-nowrap card-table table-hover cursor-pointer">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link v-slot="{ navigate }" custom v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.appointments.detail}/${item.id}`">
                  <tr @click="navigate" class="text-primary">
                    <td class="fw-bold">Psi {{ item.psychologist?.name }}</td>
                    <td class="">{{ item.date.toFormattedDate(4) }}</td>
                    <td class="">{{ item.date.toFormattedDate(8) }}</td>
                    <td class=" ">
                      <span class="text-secondary fw-bold"
                        v-if="item.formattedReschedule && item.formattedReschedule.status == 'pending'">{{
                          item.formattedReschedule.name }}</span>
                      <span v-else :class="item.formattedStatus.color" class="fw-bold">{{ item.formattedStatus?.name
                        }}</span>
                    </td>
                  </tr>
                </router-link>
              </tbody>
            </table>
          </div> -->
    </div>

    <!-- <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div> -->
  </div>
</template>
<script>
import NotificationsListController from "./NotificationsListController";
export default NotificationsListController;
</script>
<style scoped></style>
