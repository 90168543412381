
import { toggleModal } from "@/components/ModalComponent.vue";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            submitting: false
        }
    },
    methods: {
        async handleAccountDelete() {
            try {
                this.submitting = true
                await this.$userService.deleteAccount();
                this.$router.push(this.$paths.login);
                this.$eventBus.$emit("showToast", {
                    title: 'Sua conta foi excluída',
                    msg: 'Todas as suas informações foram apagadas com sucesso',
                });
                toggleModal('account_delete')
            } catch (error: any) {
                this.$eventBus.$emit('showErrorToast', { msg: error?.message || 'Erro ao excluir conta' })
                console.log(error);
            } finally {
                this.submitting = false
            }
        }
    }
})