import { computed, defineComponent } from 'vue';
import MyAccount from './MyAccount/MyAccount.vue'
import PartnershipDetail from './PartnershipDetail/PartnershipDetail.vue'
import NotificationsList from './NotificationsList/NotificationsList.vue'
import Help from './Help/Help.vue'
import { EOffcanvasViews, navigateCanvas, OffcanvasViewsConfig } from '@/components/Offcanvas/OffcanvasViews/OffcanvasViewsController';
import { useMainStore } from '@/store';
import { storeToRefs } from 'pinia';
export default defineComponent({
  setup() {
    const store = useMainStore()
    const { session } = storeToRefs(store)
    const defaultPaymentMethod = computed((): string => {
      const pm = store.session?.default_payment_method
      if (pm?.type == 'card') {
        return `**** ${pm.info.last_digits}`
      }
      if (pm?.type == 'boleto') {
        return 'Boleto'
      }
      return ''
    })

    return {
      userSession: session,
      menu_items: [
        {
          title: 'Minha conta',
          description: store.session?.email,
          icon: 'envelope',
          action: () => {
            navigateCanvas(EOffcanvasViews.myAccount)
          }
        },
        {
          title: 'Informações de pagamento',
          description: defaultPaymentMethod.value,
          icon: 'wallet',
          action: () => {
            navigateCanvas(EOffcanvasViews.paymentMethods)
          }
        },
        {
          title: 'Notificações',
          description: 'Próximas sessões e confirmação',
          icon: 'bell',
          action: () => {
            navigateCanvas(EOffcanvasViews.notifications)
          }
        },
        {
          title: 'Ajuda',
          description: 'Precisa de ajuda com o app?',
          icon: 'plane',
          action: () => {
            navigateCanvas(EOffcanvasViews.help)
          }
        },
        {
          title: 'Benefícios',
          description: 'Ative seu benefício',
          icon: 'case',
          action: () => {
            navigateCanvas(EOffcanvasViews.partnershipDetail)
          }

        },
      ],
    }
  },
  components: {
    MyAccount,
    PartnershipDetail,
    NotificationsList,
    Help
  },
  data() {
    return {
      defaultTab: 'conta',
      columns: [
        {
          name: 'Psi',
          sortName: null,
          order: null
        },
        {
          name: 'Dia',
          sortName: null,
          order: null
        },
        {
          name: 'Horário',
          sortName: null,
          order: null
        },
        {
          name: 'Status',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
  }
})
