<template>
  <div id="more">

    <div class="list-group list-group-flush my-n3 p-0">
      <div class="list-group-item text-primary cursor-pointer" @click="item.action()"
        v-for="(item, index) in menu_items">
        <div class="row align-items-center">
          <div class="col-auto">
            <PhIcons :name="item.icon" size="25" color="secondary" />
            <!-- <img src="@/assets/img/credit-card.svg" class="text-secondary" alt=""> -->
          </div>
          <div class="col-auto ms-n2">
            <h5 class="mb-1 fw-bold">
              {{ item.title }}
            </h5>
            <p class="small mb-0">
              {{ item.description }}
            </p>
          </div>
          <div class="col text-end text-primary">
            <span class="fas fa-chevron-right"></span>
          </div>
        </div>
      </div>
    </div>

    <!--     
    <Navbar />
    <Container>

      <Header title="Mais" :isSubHeader="false" :iconClass="'fa-solid fa-receipt text-primary'" :totalCount="null">
        <template v-slot:icon>
          <PhIcons name="file" :size="32" secondaryColor="#FBA486" />
        </template>
</Header>
<ul class="nav nav-tabs mb-4 border-0">
  <li tag="li" class="nav-item" v-for="(tab, index) in tabs" v-bind:key="'tabp-' + index"
    v-on:click="tab.id != 'pagamentos' ? selectedTab = tab.id : null">
    <a class="nav-link cursor-pointer pb-2 pt-0 text-subtitle" :class="{ active: selectedTab == tab.id }"
      @click.prevent="handleChangeTab(tab)">
      {{ tab.name }}
    </a>
  </li>
</ul>
<div class="tab-content" id="myTabContent2">
  <div class="tab-pane fade" id="more-account" role="tabpanel" aria-labelledby="more-account-tab" tabindex="0"
    :class="{ 'show active': selectedTab == 'conta' }">
    <MyAccount />
  </div>
  <div class="tab-pane fade" id="more-payment-methods" role="tabpanel" aria-labelledby="more-payment-methods-tab"
    tabindex="0" :class="{ 'show active': selectedTab == 'pagamentos' }">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="more-benefits" role="tabpanel" aria-labelledby="more-benefits-tab" tabindex="0"
    :class="{ 'show active': selectedTab == 'beneficios' }">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <PartnershipDetail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-pane fade" id="more-notificacoes" role="tabpanel" aria-labelledby="more-notificacoes-tab" tabindex="0"
    :class="{ 'show active': selectedTab == 'notificacoes' }">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-12">
                <NotificationsList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="more-help" role="tabpanel" aria-labelledby="more-help-tab" tabindex="0"
    :class="{ 'show active': selectedTab == 'ajuda' }">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <Help />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</Container>

-->
  </div>
</template>
<script>
import MoreController from "./MoreController";
export default MoreController;
</script>
<style scoped></style>
