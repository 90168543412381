import { useMainStore } from "@/store";
import { Component, computed, defineComponent } from "vue";
import CardsList from "@/views/PaymentMethods/Cards/CardsList.vue";
import PaymentMethods from "@/views/PaymentMethods/PaymentMethods.vue";
import { toggleOffcanvas } from "../OffcanvasComponent.vue";
import AddCard from "@/views/PaymentMethods/Cards/AddCard/AddCard.vue";
import More from "@/views/More/More.vue";
import Help from "@/views/More/Help/Help.vue";
import NotificationsList from "@/views/More/NotificationsList/NotificationsList.vue";
import PartnershipDetail from "@/views/More/PartnershipDetail/PartnershipDetail.vue";
import MyAccount from "@/views/More/MyAccount/MyAccount.vue";
import AccountUpdate from "@/views/More/MyAccount/AccountUpdate/AccountUpdate.vue";
import PasswordUpdate from "@/views/More/MyAccount/PasswordUpdate/PasswordUpdate.vue";

export interface IOffcanvasConfig {
    title: string
    path: string
    detail_id?: number
    props?: any
}
interface IOffcanvasPages {
    title: string
    component: Component
}
export type OffcanvasViewKey = keyof typeof EOffcanvasViews;
export type IOffcanvasViewsConfig = Record<OffcanvasViewKey, IOffcanvasConfig>;

export enum EOffcanvasViews {
    paymentMethods = "paymentMethods",
    addCreditCard = "addCreditCard",
    creditCards = "creditCards",
    more = "more",
    help = "help",
    notifications = "notifications",
    partnershipDetail = "partnershipDetail",
    myAccount = "myAccount",
    accountUpdate = "accountUpdate",
    passwordUpdate = "passwordUpdate",
}

const pages: Record<OffcanvasViewKey, IOffcanvasPages> = {
    [EOffcanvasViews.paymentMethods]: {
        title: "Métodos de pagamento",
        component: PaymentMethods,
    },
    [EOffcanvasViews.creditCards]:
    {
        title: "Cartões de crédito",
        component: CardsList,
    },
    [EOffcanvasViews.addCreditCard]:
    {
        title: "Adicinar Cartão",
        component: AddCard
    },
    [EOffcanvasViews.more]:
    {
        title: "Mais",
        component: More
    },
    [EOffcanvasViews.help]:
    {
        title: "Ajuda",
        component: Help
    },
    [EOffcanvasViews.notifications]:
    {
        title: "Notificações",
        component: NotificationsList
    },
    [EOffcanvasViews.partnershipDetail]:
    {
        title: "Benefícios",
        component: PartnershipDetail
    },
    [EOffcanvasViews.myAccount]:
    {
        title: "Minha Conta",
        component: MyAccount
    },
    [EOffcanvasViews.accountUpdate]:
    {
        title: "Editar Conta",
        component: AccountUpdate
    },
    [EOffcanvasViews.passwordUpdate]:
    {
        title: "Editar Senha",
        component: PasswordUpdate
    },
}

export const OffcanvasViewsConfig = Object.fromEntries(Object.entries(pages).map(([key, value]) => [key, { path: key as OffcanvasViewKey, title: value.title }])) as IOffcanvasViewsConfig
export const Views = Object.fromEntries(Object.entries(pages).map(([key, value]) => [key, value.component])) as Record<OffcanvasViewKey, IOffcanvasConfig>

export const navigateCanvas = (to: OffcanvasViewKey, config?: IOffcanvasConfig) => {
    const last = JSON.stringify(useMainStore().offcanvas_history.length)
    if (last === JSON.stringify(config)) return
    let toggle = !useMainStore().offcanvas_history.length
    const conf = config || OffcanvasViewsConfig[to]
    useMainStore().saveOffcanvasConfig(conf);
    if (toggle) toggleOffcanvas("offcanvas_views");
};


export const closeOffCanvas = () => {
    useMainStore().clearOffcanvasConfig()
    const offCanvasRef = document.getElementById("offcanvas_views");
    const hasOffCanvasOpened = offCanvasRef?.className.includes("show")
    if (hasOffCanvasOpened) {
        toggleOffcanvas("offcanvas_views");
    }
}



export default defineComponent({
    setup() {
        const store = useMainStore();
        const title = computed(() => store?.offcanvas_config?.title);
        const path = computed(() => store?.offcanvas_config?.path);
        const detail_id = computed(() => store?.offcanvas_config?.detail_id);

        const View = computed(() => {
            return Views[store?.offcanvas_config?.path as OffcanvasViewKey] || null; // Seleciona o componente correspondente
        });
        const viewProps = computed(() => {
            return store?.offcanvas_config?.props || null; // Seleciona o componente correspondente
        });

        const offcanvasClosed = () => {
            if (store.offcanvas_history.length == 1) {
                toggleOffcanvas("offcanvas_views");
                setTimeout(() => {
                    store.clearOffcanvasConfig();
                }, 200);
            }
            useMainStore().popCanvasHistory();
        };

        const config: IOffcanvasViewsConfig = OffcanvasViewsConfig

        return {
            path,
            detail_id,
            title,
            config,
            offcanvasClosed,
            viewProps,
            View
        };
    }
});

