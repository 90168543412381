import '@/assets/theme/theme.scss';
import StarRating from '@/components/StarRating.vue';
import '@/utils/Prototypes/prototypeFunctions.js';
import 'bootstrap';
import "moment/locale/pt-br";
import { createPinia } from 'pinia';
import money from 'v-money3';
import { createApp } from 'vue';
import App from './App.vue';
import './assets/js/jquery.js';
import BottomActionsContainer from './components/BottomActionsContainer.vue';
import CalendarComponent from './components/CalendarComponent/CalendarComponent.vue';
import ConfirmationAlert from './components/ConfirmationAlert/ConfirmationAlert.vue';
import Container from './components/Container.vue';
import CopyText from './components/CopyText.vue';
import EmptyState from './components/EmptyState.vue';
import AutocompleteInput from './components/FormComponents/AutocompleteInput/AutocompleteInput.vue';
import FormButton from './components/FormComponents/FormButton.vue';
import MoneyInput from './components/FormComponents/MoneyInput.vue';
import PasswordInput from './components/FormComponents/PasswordInput.vue';
import RadioInput from './components/FormComponents/RadioInput.vue';
import SelectInput from './components/FormComponents/SelectInput.vue';
import TextAreaInput from './components/FormComponents/TextAreaInput.vue';
import TextInput from './components/FormComponents/TextInput.vue';
import Header from './components/Header.vue';
import LoadingContainer from './components/LoadingContainer.vue';
import ModalComponent from './components/ModalComponent.vue';
import Navbar from './components/Navbar.vue';
import OffcanvasComponent from './components/Offcanvas/OffcanvasComponent.vue';
import PhIcons from './components/PhIcons.vue';
import SearchBar from './components/SearchBar.vue';
import TableHead from './components/TableHead.vue';
import router from './router';
import paths from './router/paths';
import AppointmentService from './services/AppointmentService';
import ChatService from "./services/ChatService";
import PaymentService from "./services/PaymentService";
import RescheduleService from "./services/RescheduleService";
import UserService from './services/UserService';
import { EventBus } from "./utils/EventBus";
import FeedbackDetail from "./views/FeedbackDetail/FeedbackDetail.vue";

import DocumentInput from "./components/DocumentInput.vue";
import VersionComponent from "./components/VersionComponent.vue";
import webSocketService from "./services/WebSocketService";


const pinia = createPinia()
const app = createApp(App);

app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$paymentService = new PaymentService()
app.config.globalProperties.$appointmentService = new AppointmentService()
app.config.globalProperties.$rescheduleService = new RescheduleService()
app.config.globalProperties.$chatService = new ChatService()
app.config.globalProperties.$paths = paths;
app.config.globalProperties.$eventBus = EventBus;
app.config.globalProperties.$baseImgUrl = process.env.VUE_APP_BASE_IMG_URL;
// app.config.globalProperties.$formattedFirstAppointmentPrice = Number(process.env.VUE_APP_FIRST_APPOINTMENT_PRICE).toCurrencyString();
app.config.globalProperties.$formattedImediateAppointmentPrice = Number(process.env.VUE_APP_IMEDIATE_APPOINTMENT_PRICE).toCurrencyString();
app.config.globalProperties.$pusher = webSocketService



app.component("Navbar", Navbar);
app.component("LoadingContainer", LoadingContainer);
app.component("EmptyState", EmptyState);
app.component("Container", Container);
app.component("MoneyInput", MoneyInput);
app.component("SearchBar", SearchBar);
app.component("TextInput", TextInput);
app.component("RadioInput", RadioInput);
app.component("SelectInput", SelectInput);
app.component("TextAreaInput", TextAreaInput);
app.component("PasswordInput", PasswordInput);
app.component("FormButton", FormButton);
app.component("TableHead", TableHead);
app.component("ModalComponent", ModalComponent);
app.component("Header", Header);
app.component("ConfirmationAlert", ConfirmationAlert);
app.component("AutocompleteInput", AutocompleteInput);
app.component("PhIcons", PhIcons);
app.component("BottomActionsContainer", BottomActionsContainer);
app.component("CopyText", CopyText);
app.component("CalendarComponent", CalendarComponent);
app.component("FeedbackDetail", FeedbackDetail);
app.component("StarRating", StarRating);
app.component("DocumentInput", DocumentInput);
app.component("OffcanvasComponent", OffcanvasComponent);
app.component("VersionComponent", VersionComponent);


app.use(pinia).use(router).use(money).mount('#app')
