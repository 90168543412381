import { defineComponent } from 'vue';
export default defineComponent({
  mounted() {

  },
  data() {
    return {
      whatsappText: `Olá, Preciso de ajuda com o aplicativo do Psiapp para pacientes.`,
      whatsappLink: `https://wa.me/${process.env.VUE_APP_PSIAPP_WHATSAPP_NUMBER}?text=${this.whatsappText}`,
      email: `mailto:${process.env.VUE_APP_PSIAPP_EMAIL}`
    };
  },
  methods: {

  }
})
