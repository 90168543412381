import { TDocumentInputResponse } from "@/components/DocumentInput.vue";
import { toggleModal } from "@/components/ModalComponent.vue";
import { useMainStore } from "@/store";
import { EventBus } from "@/utils/EventBus";
import { isNameValid } from "@/utils/FormValidators";
import { phoneCodes } from "@/utils/phoneCodes";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
    setup() {
        const store = useMainStore()
        const { session } = storeToRefs(store)
        return {
            v$: useVuelidate(),
            userSession: session
        }
    },
    mounted() {
        if (this.userSession) {
            this.form = {
                name: this.userSession?.name,
                country_code: this.userSession?.country_code,
                phone: this.userSession?.phone,
                nickname: this.userSession?.nickname,
                document_number: '',
                document_number_valid: false
            }
            this.countryCodeChanged(this.userSession?.country_code)
            this.form.phone = this.userSession?.phone

        }
    },
    data() {
        return {
            v$: useVuelidate(),
            phoneCodes: phoneCodes,
            form: {
                name: '',
                country_code: '',
                phone: '',
                nickname: '',
                document_number: '',
                document_number_valid: false
            },
            phoneMask: '(##) #####-####',
            phoneCode: phoneCodes[0] as any,
            submitting: false,
            errorMessage: ''
        }
    },
    validations() {
        return {
            form: {
                name: {
                    required: helpers.withMessage('Informe um nome válido', required),
                    minLength: helpers.withMessage('Informe um nome válido', minLength(3)),
                    fullName: helpers.withMessage('Informe um nome válido', (value: string) => isNameValid(value)),
                },
                country_code: {
                    required: helpers.withMessage('Informe o DDI', required),
                },
                phone: {
                    required: helpers.withMessage('Informe um telefone válido', required),
                    minLength: helpers.withMessage('Informe um telefone válido', minLength(this.phoneCode?.mask.length)),
                },
                nickname: {
                    required: helpers.withMessage('Informe um apelido válido', required),
                    minLength: helpers.withMessage('Informe um apelido válido', minLength(3)),
                },
                document_number: {},
                document_number_valid: {
                    required: helpers.withMessage("Informe um documento válido", (value: boolean) => value),
                }

            }
        }
    },
    methods: {
        async handleAccountUpdate() {
            try {
                if (this.userSession) {
                    this.submitting = true;
                    const params: any = {
                        ...this.form,
                        phone: (this.form.phone as any).clear()
                    };
                    const res = await this.$userService.update(this.userSession?.id, params);
                    await this.$userService.syncSession();
                    EventBus.$emit('accountUpdated')
                    this.$eventBus.$emit("showToast", {
                        title: "Informações alteradas",
                        msg: `Suas informações form alteradas com sucesso`,
                    });
                    toggleModal('account_update')
                }
            } catch (error: any) {
                console.log(error);
                this.errorMessage = error.message
            } finally {
                this.submitting = false;
            }
        },
        countryCodeChanged(value: string) {
            this.form.phone = ''
            const pc = this.phoneCodes.find(elem => elem.dial_code == value)
            if (pc) {
                this.phoneCode = pc
                this.phoneMask = pc?.mask || '##########'
            }
        },
        documentChanged(values: TDocumentInputResponse) {
            console.log(values)
            this.form.document_number_valid = values.valid
            this.form.document_number = values.value
        },
        // documentChanged(values: any) {
        //     this.form.document_number_valid = values.valid
        //     this.form.document_number = values.value
        // }
    }
})